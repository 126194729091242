// import Splide from "@splidejs/splide";

document.addEventListener('DOMContentLoaded', function () {
    const Splide = window.Splide;

    var elms = document.querySelectorAll('.splide-slider.seriesSlider');
    const allSlides = []

    elms.forEach(elem => {
        var slide = new Splide(elem).mount();
        allSlides.push(slide);
    });


    var elms = document.querySelectorAll('.splide-slider.seriesLogoSlider');
    const allSeriesLogos = [];
    elms.forEach(elem => {
        var logo = new Splide(elem, {
            breakpoints: {
                900: {
                    perPage: 1,
                },
                1200: {
                    perPage: 2,
                },
                1700: {
                    perPage: 3,
                },
            },
        }).mount();
        allSeriesLogos.push(logo)

        let links = elem.querySelectorAll('.splide-slider.seriesLogoSlider a.splide__slide');
        links.forEach(link => {
            // Listen for the click.
            link.addEventListener('click', function (e) {
                e.preventDefault();
                links.forEach(l => {
                    l.classList.remove('show-pointer')
                })
                this.classList.add('show-pointer')
                var targetSlide = this.getAttribute('href').replace('#', '');
                allSlides.forEach(slide => {
                    var slideHTML = document.getElementById(slide.root.id)
                    var theSlide = slideHTML.querySelector('[data-series_id="' + targetSlide + '"]');
                    if (!theSlide) return;
                    var slideId = theSlide.getAttribute('id');
                    var slideNumber = parseInt(slideId.substring(slideId.indexOf("slide") + 5)) - 1;
                    slide.go(slideNumber);

                });
            }, {
                capture: true,
                passive: true
            })
        })

        // Now... sync active main slide to arrow
        allSlides.forEach(splide => {
            splide.on('visible', function (item) {
                var slideTarget = item.slide.getAttribute('data-series_id')
                let links = elem.querySelectorAll('.splide-slider.seriesLogoSlider a.splide__slide');
                links.forEach(l => {
                    l.classList.remove('show-pointer')
                })
                allSeriesLogos.forEach(slide => {
                    var slideHTML = document.getElementById(slide.root.id)
                    var theSlide = slideHTML.querySelector('[href="#' + slideTarget + '"]');
                    if (!theSlide) return;
                    theSlide.classList.add('show-pointer');
                    var slideId = theSlide.getAttribute('id');
                    var slideNumber = parseInt(slideId.substring(slideId.indexOf("slide") + 5)) - 1;
                    slide.go(slideNumber);
                })
            });
        });
    });
});